import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import router from './router'
import PrimeVue from 'primevue/config';
import "primevue/resources/themes/lara-light-indigo/theme.css";     
import "primevue/resources/primevue.min.css";
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import Avatar from 'primevue/avatar';
import 'primeicons/primeicons.css';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';


import store from './stores';
import '@/assets/styles.scss';



import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faArrowLeft, faArrowRight, faLocationDot, faUtensils, faUser, faUserLarge, faMobile, faMagnifyingGlass, faChevronLeft, faChevronRight, faStar, faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import io from 'socket.io-client';

// Create a WebSocket connection to your backend

const socket = io('ws://your-websocket-server-host:port');

// Add the socket instance to the Vue app as a global property
const app = createApp(App);
app.config.globalProperties.$socket = socket;

/* add icons to the library */
library.add(faUtensils, faLocationDot, faUser, faArrowRight, 
    faArrowLeft, faUserLarge, faMobile, faMagnifyingGlass, 
    faFacebook, faInstagram, faTwitter, faChevronLeft, faChevronRight, 
    faStar, faMoneyBill)

createApp(App).use(router).use(store).use(PrimeVue).use(ToastService).use(ConfirmationService).use(DialogService).component('Button', Button).component('Avatar', Avatar).component('Menu', Menu).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
