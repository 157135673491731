export default {
  namespaced: true,
  state: {
    loggedIn: false,
    user: null, // Add user property to store the user object

  },
  mutations: {
    setLoggedIn(state, value) {
      state.loggedIn = value;
    },
    setUser(state, user) { // Add setUser mutation to store the user object
      state.user = user;
    },
  },
  actions: {
    setUser({ commit, rootState }, user) {
      // localStorage.setItem('user', JSON.stringify(user));
      commit('setUser', user);
      commit('authusers/setUser', user, { root: true }); // Commit the mutation from the 'auth' module
    },
    loadUser({ commit }) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        commit('setUser', user);
        commit('authusers/setUser', user, { root: true }); // Commit the mutation from the 'auth' module
      }
    },
    clearUser({ commit }) {
      localStorage.removeItem('user');
      commit('setUser', null);
      commit('authusers/setUser', null, { root: true }); // Commit the mutation from the 'auth' module
    }
  }
};
