<template>
  <div>
    <swiper :slidesPerView="3" :spaceBetween="0" :freeMode="true" :pagination="{ clickable: true }" :modules="modules"
      :navigation="true" class="mySwiper">
      <swiper-slide>
        <img class="w-full h-72" src="../assets/pic1.jpg" />
      </swiper-slide>
      <swiper-slide>
        <img class="w-full h-72" src="../assets/pic3.jpg" />
      </swiper-slide>
      <swiper-slide>
        <img class="w-full h-72" src="../assets/pic2.jpg" />
      </swiper-slide>
      <swiper-slide>
        <img class="w-full h-72" src="../assets/pic4.jpg" />
      </swiper-slide>
      <swiper-slide>
        <img class="w-full h-72" src="../assets/pic5.jpg" />
      </swiper-slide>
    </swiper>

    <div class="container mx-auto flex justify-center py-10">
      <div class="w-2/3">
        <div class="flex flex-col lg:flex-row">
          <div class="sm:w-1/1 lg:w-1/2">
            <h1 class="text-3xl font-bold mb-4">{{ restaurant.name }}</h1>
            <div class="mb-4 flex flex-row items-center gap-2">
              <span class="bg-[#DDF0D6] w-fit px-2 py-1 rounded-md">
                <font-awesome-icon class="w-5 h-5 text-[#9EB23B]" icon="fa-solid fa-location-dot" />
              </span>
              <p v-if="restaurant && restaurant.address && restaurant.city">{{ restaurant.address + ", " }} <span>{{
                restaurant.city }}</span> </p>
              <p v-else>
              <div class="h-2.5 bg-gray-200 rounded-full animate-pulse w-44"></div>
              </p>
            </div>
            <div class="mb-4 flex flex-row items-center gap-2">
              <span class="bg-[#DDF0D6] w-fit px-2 py-1 rounded-md">
                <font-awesome-icon class="w-5 h-5 text-[#9EB23B]" icon="fa-solid fa-utensils" />
              </span>
              <p v-if="restaurant && restaurant.categories_restaurant && restaurant.categories_restaurant.length">{{
                restaurant.categories_restaurant[0].category_name }}</p>
              <p v-else>
              <div class="h-2.5 bg-gray-200 rounded-full animate-pulse w-40"></div>
              </p>
            </div>
            <div class="mb-4 flex flex-row items-center gap-2">
              <span class="bg-[#DDF0D6] w-fit px-2 py-1 rounded-md">
                <font-awesome-icon class="w-5 h-5 text-[#9EB23B]" icon="fa-solid fa-money-bill" />
              </span>
              <p v-if="restaurant && restaurant.Menu">{{ formatPrice(restaurant.Menu.avg_price) + " DH" }}</p>
              <p v-else>
              <div class="h-2.5 bg-gray-200 rounded-full animate-pulse w-24"></div>
              </p>
            </div>

            <div class="mb-6">
              <span class="text-gray-700 font-medium text-2xl">Offres</span>

              <div v-if="Offres === []" v-for="offre in Offres" class="card bg-gray-100 py-4 px-6 rounded-lg shadow-lg">
                <div class="flex justify-between items-center mb-2">
                  <p class="text-white bg-[#9EB23B] py-1 px-2 rounded-lg inline-block">
                    -{{ offre.discount_value }}{{ offre.discount_type === 'fixed' ? ' DH' : '%' }}
                  </p>
                  <p class="font-semibold text-sm text-gray-700 ml-2">{{ offre.discount_type }}</p>
                </div>
                <p class="text-sm text-gray-500 mb-1">
                  <i class="pi pi-calendar mr-1"></i>
                  <strong>Valid</strong> from {{ formatDate(offre.start_date) }} to {{ formatDate(offre.end_date)
                  }}
                </p>
                <p class="text-sm text-gray-500 mb-1">
                  <i class="pi pi-check-circle mr-1"></i>
                  <strong>Max Uses:</strong> {{ offre.max_uses !== null ? offre.max_uses : 'Unlimited' }}
                </p>
              </div>
              <div v-else>
                <p class="text-white bg-[#9EB23B] py-1 px-2 rounded-lg inline-block mt-5">
                  Theres no Offres in this restaurant
                </p>


              </div>
            </div>
            <div class="mb-4">
              <span class="text-gray-700 font-medium text-3xl">Menu</span>
              <div v-if="isLoading">
                <div v-for="index in skeletonCount" :key="index" class="flex pt-4 items-center justify-between">
                  <div>
                    <div class="h-2 bg-gray-300 rounded-full  w-24 mb-4"></div>
                    <div class="w-52 h-2 bg-gray-200 rounded-full "></div>
                  </div>
                  <div class="h-2.5 bg-gray-300 rounded-full  w-12"></div>

                </div>

              </div>
              <div v-else>
                <!-- <div v-for="category in menuItems" :key="category.id_category" class="my-6">
                  <h3 class="text-lg font-semibold mb-2">{{ category.category_name }}</h3>
                  <div v-for="item in category.items" :key="item.id" class="flex justify-between mb-2">
                    <div>
                      <p class="font-semibold">{{ item.name }}</p>
                      <p class="text-gray-500 max-w-sm">{{ item.description }}</p>
                    </div>
                    <p class="font-semibold">{{ item.price }}</p>
                  </div>
                  <div class="border-t border-gray-300 my-4"></div>

                </div> -->
                <div v-for="category in menuItems" :key="category.id_category" class="my-6">
                  <h3 class="text-2xl font-semibold mb-4">{{ category.category_name }}</h3>
                  <div class="grid grid-cols-1  gap-6">
                    <div v-for="item in category.items" :key="item.id" class="bg-white rounded-lg shadow-md p-6">
                      <div class="flex justify-between mb-4">
                        <div>
                          <p class="font-semibold text-gray-800">{{ item.name }}</p>
                          <p class="text-gray-500">{{ item.description }}</p>
                        </div>
                        <p class="font-semibold text-gray-800">{{ item.price }} </p>
                      </div>
                    </div>
                  </div>
                </div>


              </div>


            </div>
            <div class="mb-4">
              <span class="text-gray-700 font-medium text-3xl">Reviews</span>
              <div class="bg-[#F9FAFA] rounded-lg  p-6 my-6">
                <div class="flex items-center">
                  <div class="w-1/4 ">

                    <div class="circle-wrap">
                      <div class="circle">
                        <div class="mask full">
                          <div class="fill"></div>
                        </div>
                        <div class="mask half">
                          <div class="fill"></div>
                        </div>
                        <div class="inside-circle"> <span class="text-md">{{ Math.round(restaurant.avg_rating) * 2
                        }}</span><span class="text-sm text-gray-600">/10</span> </div>
                      </div>
                    </div>
                  </div>
                  <div class="h-px bg-gray-200"></div>

                  <div class="w-3/4 ">
                    <div v-for="rating in getratingPercentages()" :key="rating.value"
                      class="flex items-center justify-end w-full mb-2">

                      <span class="text-sm font-medium text-black ">{{ rating.value }}</span>
                      <div class="w-2/4 h-3 mx-4 bg-[#DDF0D6] rounded-full">
                        <div class="h-3 bg-[#9EB23B] rounded-full" :style="{ 'width': rating.percent }"></div>
                      </div>
                    </div>

                  </div>

                </div>
                <div class="border border-gray-400 p-4 mt-4 rounded-lg">
                  <div class="flex flex-row gap-2 items-center">
                    <img src="../assets/reviews-verified.69d8066e.svg" />
                    <p class="font-semibold text-lg">Real experience from real diners</p>
                  </div>
                  <p class="ml-8 text-sm">Only people who have booked with MiamDeal can leave a rating and review.</p>
                </div>
              </div>
              <div class="my-6">
                <div v-if="isLoading">
                  <div v-for="index in skeletonCount" :key="index" class="flex items-center mt-4 space-x-3">
                    <svg class="text-gray-200 w-14 h-14 " aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                        clip-rule="evenodd"></path>
                    </svg>
                    <div>
                      <div class="h-2.5 bg-gray-200 rounded-full  w-32 mb-2"></div>
                      <div class="w-48 h-2 bg-gray-200 rounded-full "></div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="grid gap-6">
                    <div v-for="review in reviews" :key="review.id" class="bg-white border rounded-lg shadow-sm p-6">
                      <div class="flex items-center justify-between mb-4">
                        <div class="flex items-center mb-4">
                          <img
                            src="https://w7.pngwing.com/pngs/481/915/png-transparent-computer-icons-user-avatar-woman-avatar-computer-business-conversation-thumbnail.png"
                            alt="User Avatar" class="w-10 h-10 rounded-full mr-4">
                          <div>
                            <p class="text-gray-800 text-lg font-semibold">User</p>
                            <p class="text-xs text-gray-500">{{ review.created_at }}</p>
                          </div>
                        </div>
                        <div class="flex items-center">
                          <p class="text-3xl text-gray-900">{{ review.rating }}</p>
                          <span class="text-sm text-gray-500">/5</span>
                        </div>
                      </div>
                      <p class="text-gray-700">{{ review.comment }}</p>
                    </div>
                  </div>

                </div>
              </div>

            </div>


          </div>
          <div class="sm:w-1/1 lg:w-1/2 md:ml-10 ">
            <div class="sticky top-20">
              <div class="bg-white p-4 rounded shadow">
                <h2 class="text-xl font-bold mb-4">Réservez votre table</h2>
                <div class="mb-4">
                  <label for="date1" class="text-gray-700">Date:</label>
                  <Calendar v-model="date" :minDate="minDate" class="w-full px-3 py-2" touchUI />

                  <!-- <Calendar v-model="date"  class="w-full px-3 py-2 " /> -->
                </div>
                <div class="mb-4">
                  <label for="time" class="text-gray-700">Heure:</label>
                  <Calendar id="calendar-timeonly" v-model="time" timeOnly class="w-full px-3 py-2 " />
                </div>

                <div class="mb-4">
                  <label for="party-size" class="text-gray-700">Combien d'invités?</label>
                  <InputNumber v-model="value1" inputId="stacked-buttons" showButtons class="w-full px-3 py-2  " />

                </div>
                <div class="mb-4">
                  <label for="party-size" class="text-gray-700">Demande Spéciale</label>
                  <Textarea class="w-full px-3 py-2 " v-model="sprequest" autoResize rows="5" cols="30" />

                </div>
                <button @click="Make_booking" class="bg-[#9EB23B] text-white px-4 py-2 rounded-md">Reservez
                  maintenant</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LastSection />

  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, FreeMode } from 'swiper';
import axios from 'axios';
import Calendar from 'primevue/calendar';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import LastSection from '../components/HowItWorks.vue';
import Skeleton from 'primevue/skeleton';
import Textarea from 'primevue/textarea';

export default {
  data() {
    return {
      restaurant: [],
      baseurl: 'https://goodresa.com/images/',
      apiUrl: 'https://goodresa.com/api/Restaurant',
      apiToken: 'vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy',
      menuItems: [],
      skeletonCount: 5,
      isLoading: true,
      reviews: [],
      date: null,
      time: null,
      value1: 1,
      isauth: false,
      averageRating: 4.5,
      ratings: [],
      sprequest: null,
      minDate: null,
      Offres: []
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const restaurantId = this.$route.params.id;
        this.minDate = new Date();

        const response = await axios.get(`${this.apiUrl}/get-details-Restaurant/${restaurantId}`, {
          headers: {
            Authorization: `Bearer ${this.apiToken}`,
          },
        });

        this.restaurant = response.data.Restaurants;
        this.menuItems = response.data.Restaurants.Menu && response.data.Restaurants.Menu.Menu_Items
          ? response.data.Restaurants.Menu.Menu_Items
          : [];
        this.reviews = response.data.Restaurants.Reviews;

        if (response.data.Restaurants.Offres) {
          this.Offres = response.data.Restaurants.Offres;
        } else {
          this.Offres = [];
        }

        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.isLoading = false;
      }
    },

    formatDate(dateStr) {
      // Your date formatting logic here
    },

    formatPrice(price) {
      return parseFloat(price).toFixed(2);
    },

    getratingPercentages() {
      // Your getratingPercentages method logic here
    },

    async Make_booking() {
      console.log("Date: " + this.date);
      console.log("Time: " + this.time);
      const mysqlDateFormat = this.date.toISOString().substr(0, 10);
      console.log(mysqlDateFormat);

      const restaurantId = this.$route.params.id;
      const url = 'https://goodresa.com/api/Booking/make-booking';
      const token = localStorage.getItem('auth_token');
      const apiToken = 'vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy';
      const config = {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          'auth-token': token,
        },
      };

      const params = {
        restaurant_id: restaurantId,
        date_booking: mysqlDateFormat,
        time_booking: this.time,
        party_size: this.value1,
        special_request: this.sprequest
      };

      try {
        const response = await axios.post(url, null, { params, headers: config.headers });
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    Calendar,
    InputNumber,
    Button,
    Divider,
    Skeleton,
    Textarea,
    LastSection
  },
  setup() {
    return {
      modules: [Navigation, Pagination, FreeMode],
    };
  },
};
</script>

<style scoped>
.p-inputnumber-button-up {
  background-color: #9EB23B !important;
  border-color: #9EB23B;


}

.p-inputnumber-button-down {
  background-color: #9EB23B !important;
  border-color: #9EB23B;

}

.p-inputtext:enabled:focus {
  border-color: #9EB23B !important;
  box-shadow: 0 0 0 0.2rem #eff7c8 !important;

}

.p-inputtext:enabled:hover {
  border-color: #9EB23B !important;

}

.p-button {
  border: 1px solid #c6cf98 !important
}

.circle-wrap {
  /* margin: 150px auto; */
  width: 150px;
  height: 150px;
  background: #F9FAFA;
  border-radius: 50%;
  border: 1px solid #cdcbd0;
}

.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
  width: 150px;
  height: 150px;
  position: absolute;
  border-radius: 50%;
}

.circle-wrap .circle .mask {
  clip: rect(0px, 150px, 150px, 75px);
}

.circle-wrap .inside-circle {
  width: 122px;
  height: 122px;
  border-radius: 50%;
  background: #F9FAFA;
  line-height: 120px;
  text-align: center;
  margin-top: 14px;
  margin-left: 14px;
  position: absolute;
  z-index: 100;
  font-weight: 700;
  font-size: 2em;
}

/* color animation */

/* 3rd progress bar */
.mask .fill {
  clip: rect(0px, 76px, 150px, 0px);
  background-color: #9EB23B;
}

.mask.full,
.circle .fill {
  animation: fill ease-in-out 3s;
  transform: rotate(135deg);
}

@keyframes fill {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(135deg);
  }
}
</style>
