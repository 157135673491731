<script setup>
import { useLayout } from '@/layout/composables/layout';
import { computed } from 'vue';

const { layoutConfig } = useLayout();

</script>

<template>
    <div class="layout-footer">

        <img src="../assets/MiamDeallogo.svg" alt="Logo" height="20"  class="mr-2" />
    </div>
</template>
<style lang="scss" scoped></style>
