<template>
  <div id="app">
    <Header v-if="!$route.meta.hideHeaderFooter" />
    <RouterView />
    <Footer v-if="!$route.meta.hideHeaderFooter" />
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default {
  components: {
    Header,
    Footer
  }
};
</script>

<style scoped></style>