<script setup>
import { ref, onMounted } from 'vue';


import AppMenuItem from './AppMenuItem.vue';

const adminItems = [
    { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard' },
    { label: 'Change Status', icon: 'pi pi-fw pi-home', to: '/dashboard/Changestatus' },
    { label: 'User handling', icon: 'pi pi-fw pi-home', to: '/dashboard/User_section' },
    { label: 'Se déconnecter', icon: 'pi pi-sign-out', command: () => { /* ... */ } },
];

const userItems = [
    { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard/ownerdashboard' },
    { label: 'Booking handling', icon: 'pi pi-fw pi-home', to: '/dashboard/bookinghandling' },
    { label: 'Restaurant offre', icon: 'pi pi-fw pi-home', to: '/dashboard/add-offre' },
    { label: 'Restaurant images', icon: 'pi pi-fw pi-home', to: '/dashboard/add-images' },
    { label: 'Restaurant Menus', icon: 'pi pi-fw pi-home', to: '/dashboard/add-menus' },
    { label: 'Restaurant Menu items', icon: 'pi pi-fw pi-home', to: '/dashboard/add-menu-items' },
    { label: 'Se déconnecter', icon: 'pi pi-sign-out', command: () => { /* ... */ } },
];

const model = ref([

    {
        label: 'Menu',
        items: [
            { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard' },
            { label: 'Change Status', icon: 'pi pi-fw pi-home', to: '/dashboard/Changestatus' },
            { label: 'User handling', icon: 'pi pi-fw pi-home', to: '/dashboard/User_section' },
            { label: 'Restaurant offre', icon: 'pi pi-fw pi-home', to: '/dashboard/add-offre' },
            { label: 'Restaurant images', icon: 'pi pi-fw pi-home', to: '/dashboard/add-images' },
            { label: 'Restaurant Menus', icon: 'pi pi-fw pi-home', to: '/dashboard/add-menus' },
            { label: 'Restaurant Menu items', icon: 'pi pi-fw pi-home', to: '/dashboard/add-menu-items' },


        ]
    }

]);


onMounted(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        if (user.role !== 'admin') {
            model.value[0].items = userItems
        } else {
            model.value[0].items = adminItems
        }
    }
});

</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="item">
            <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
            <li v-if="item.separator" class="menu-separator"></li>
        </template>

    </ul>
</template>

<style lang="scss" scoped></style>
