<template>
  <div>
    <HeroSection />
    <SelectionsSection />
    <RestaurantsSection />
    <YumsSection />
    <LastSection />
    <HowItWorks />
  </div>
</template>

<script>
// @ is an alias to /src
import HeroSection from '../components/HeroSection.vue';
import SelectionsSection from '../components/SelectionsSection.vue';
import RestaurantsSection from '../components/RestaurantsSection.vue';
import YumsSection from '../components/YumsSection.vue';
import LastSection from '../components/LastSection.vue';
import HowItWorks from '@/components/HowItWorks.vue';

export default {
  name: 'HomeView',
  components: {
    HeroSection,
    SelectionsSection,
    RestaurantsSection,
    YumsSection,
    LastSection,
    HowItWorks
  }
}
</script>
