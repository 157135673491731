<template>
    <div class="lg:text-left bg-[#F2F6F4]">
        <div class="max-w-screen-xl px-8 py-12  mx-auto  sm:px-6 md:px-6">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-8  justify-items-center">
                <div class="lg:mt-0  flex flex-col gap-6">
                    <p class="text-[#A34723] text-2xl md:text-3xl lg:text-4xl font-bold">
                        Savourez chaque bouchée, vivez chaque instant
                    </p>
                    <div class="bg-white p-6 rounded-lg text-center">
                        <form action="" class="flex flex-col gap-4">
                            <div class="relative flex items-center  focus-within:text-gray-600">
                                <font-awesome-icon class="w-5 h-5 text-[#9EB23B] absolute ml-3 pointer-events-none"
                                    icon="fa-solid fa-utensils" />
                                <input type="text" name="search1" placeholder="Cuisine, nom du restaurant..."
                                    autocomplete="off"
                                    class="w-full pr-3 pl-10 py-2 font-semibold placeholder-gray-400 bg-gray-100 text-black rounded-lg border-none  focus:ring-gray-200 focus:ring-1">
                            </div>
                            <div class="relative flex items-center focus-within:text-gray-600">
                                <font-awesome-icon class="w-5 h-5 text-[#9EB23B] absolute ml-3 pointer-events-none"
                                    icon="fa-solid fa-location-dot" />
                                <input type="text" name="search2" placeholder="Maroc" autocomplete="off"
                                    class="w-full pr-3 pl-10 py-2 font-semibold placeholder-gray-400 bg-gray-100 text-black rounded-lg border-none  focus:ring-gray-200 focus:ring-1">
                            </div>
                            <button type="submit" class="bg-[#9EB23B] w-full rounded-lg py-2 text-white">
                                <font-awesome-icon class=" text-white" icon="fa-solid fa-magnifying-glass" />
                                Rechercher</button>
                        </form>
                    </div>
                </div>
                <div class="mb-12 lg:mb-0 hidden max-w-full md:flex">
                    <img src="../assets/image-hero.png" class="w-full" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped></style>