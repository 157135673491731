<template>
  <div class="card">
    <h4 class="text-center">Add images to your restaurant</h4>
    <Toast />
    <FileUpload name="demo[]" :url="uploadUrl" @upload="onAdvancedUpload" :multiple="true" accept="image/*"
      :maxFileSize="1000000">
      <template #empty>
        <p>Drag and drop files here to upload.</p>
      </template>
    </FileUpload>
  </div>
</template>
  
<script>
import FileUpload from 'primevue/fileupload';
import Toast from 'primevue/toast';
import axios from 'axios';

export default {
  components: {
    Toast,
    FileUpload,
  },
  data() {
    return {
      uploadUrl: 'https://goodresa.com/api/Owner/add-Restaurant-images/',
      apiToken: 'vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy',
      id_restaurant: null
    };
  },
  mounted() {
    const token = localStorage.getItem('auth_token');

    axios.get("https://goodresa.com/api/Owner/getrestaubyowner", {
      headers: {
        Authorization: `Bearer ${this.apiToken}`,
        'auth-token': token

      }
    })
      .then((response) => {
        this.id_restaurant = response.data.Restaurants.id;
      })
      .catch(error => {
        console.log(error);
      })
  },
  methods: {
    async onAdvancedUpload(event) {
      const files = event.files;

      const authToken = localStorage.getItem('auth_token');

      const headers = {
        Authorization: `Bearer ${this.apiToken}`,
        'auth-token': authToken
      };

      const formData = new FormData();
      files.forEach((file) => {
        formData.append('image[]', file);
      });

      axios
        .post(this.uploadUrl + this.id_restaurant, formData, { headers })
        .then((response) => {
          console.log(response.data);
          if (response.data.status === true) {
            this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Images Uploaded', life: 3000 });
            const formData = new FormData();

          } else {
            this.$toast.add({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
          }
        })
        .catch((error) => {
          console.error(error);
          this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error adding menu item', life: 3000 });
        });

    },
  },
};
</script>
  