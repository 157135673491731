<template>
    <div class=" mx-auto max-w-screen-xl px-2 py-4 mt-4">
        <div class="flex flex-row justify-between mb-6 px-5">
            <h1 class="text-3xl font-normal text-black">Sélections MiamDeal</h1>
            <div class="flex flex-row gap-2">
                <button class="button-prev bg-gray-100 rounded-md">
                    <font-awesome-icon class=" text-gray-600 px-2 py-0" icon="fa-solid fa-chevron-left" />
                </button>
                <button class="button-next bg-gray-100   rounded-md">
                    <font-awesome-icon class=" text-gray-600 px-2 py-0" icon="fa-solid fa-chevron-right" />
                </button>
            </div>
        </div>

        <swiper :modules="modules" grab-cursor:true :slides-per-view="1" :breakpoints="{
                '640': {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                '768': {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                '1024': {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
            }" :pagination="{ el: '.swiper-pagination', clickable: true }" :navigation="{
            nextEl: '.button-next',
            prevEl: '.button-prev',
            clickable: true,
        }"  class="swiper_container">

            <swiper-slide v-for="card in cards">
                <a :href="card.url">
                    <div class=" rounded-lg p-5 text-center">
                        <img class="h-50 w-full object-cover rounded-md" :src="card.image" alt="" >
                        <p class="mt-2 text-2xl font-semibold text-black">{{ card.title }}</p>
                        <p class="mt-2 mb-4 font-normal text-xl text-black">{{ card.description }}</p>
                        <a class=" text-[#9EB23B] font-semibold text-2xl" :href="card.button.link">{{ card.button.title }}</a>
                    </div>
                </a>

            </swiper-slide>


        </swiper>
    </div>
</template>
  
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation } from "swiper";
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";


export default {
    data() {
        return {
            cards: [
                {
                    image: require('../assets/img_1.png'),
                    title: "Télecharger l'application",
                    description: "Expedita sunt numquam laboriosam ratione culpa obcaecati inventore placeat repudiandae minima",
                    url: "https://google.com",
                    button: {
                        title: "En savoir plus",
                        link: "https://google.com"
                    }
                },
                {
                    image: require('../assets/img_2.png'),
                    title: "Nouveau sur le MiamDeal",
                    description: "Expedita sunt numquam laboriosam ratione culpa obcaecati inventore placeat repudiandae minima",
                    url: "https://google.com",
                    button: {
                        title: "Voir les restaurants",
                        link: "https://google.com"
                    }
                },
                {
                    image: require('../assets/img_3.png'),
                    title: "Meilleure offre en ville",
                    description: "Expedita sunt numquam laboriosam ratione culpa obcaecati inventore placeat repudiandae minima",
                    url: "https://google.com",
                    button: {
                        title: "Voir les offres",
                        link: "https://google.com"
                    }
                },
                
            ]
        }
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        

        return {
            
            Swiper,
            modules: [Navigation, Pagination],

        };
    },
};
</script>
  
<style scoped></style>
  