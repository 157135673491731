<template>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <div class="card">
            <div class="mr-4 bg-blue-100 rounded-full w-10 h-10 flex items-center justify-center mb-2">
                <i class="pi pi-calendar-minus"></i>
            </div>
            <div>
                <span class="block text-gray-500 font-medium mb-3">Reservation</span>
                <div class="text-gray-900 font-medium text-xl">{{ totalbookinday }}</div>
                <span class="text-green-500 font-medium">{{ totalbookingsincelastweek }} new </span>
                <span class="text-gray-500"> since last Week</span>
            </div>
        </div>
        <div class="card">
            <div class="mr-4 bg-orange-100 rounded-full w-10 h-10 flex items-center justify-center mb-2">
                <i class="pi pi-stop "></i>
            </div>
            <div>
                <span class="block text-gray-500 font-medium mb-3">Restaurants</span>
                <div class="text-gray-900 font-medium text-xl">{{ totalrestaurant }}</div>
                <span class="text-green-500 font-medium">{{ totalrestaurantlastweek }} new</span>
                <span class="text-gray-500"> since last week</span>
            </div>
        </div>
        <div class="card ">
            <div class="mr-4 bg-purple-100 rounded-full w-10 h-10 flex items-center justify-center mb-2">
                <i class="pi pi-comments"></i>
            </div>
            <div>
                <span class="block text-gray-500 font-medium mb-3">Reviews</span>
                <div class="text-gray-900 font-medium text-xl">{{ totalreviewday }}</div>
                <span class="text-green-500 font-medium">{{ totalreviewlastweek }}</span>
                <span class="text-gray-500"> since last week</span>
            </div>
        </div>
        <div class="card">
            <div class="mr-4 bg-cyan-100 rounded-full w-10 h-10 flex items-center justify-center mb-2">
                <i class="pi pi-users"></i>
            </div>
            <div>
                <span class="block text-gray-500 font-medium mb-3">Utilisateurs</span>
                <div class="text-gray-900 font-medium text-xl">{{ totaluser }}</div>
                <span class="text-green-500 font-medium">{{ totalusersincelastweek }}</span>
                <span class="text-gray-500"> newly registered</span>
            </div>
        </div>
    </div>
    <div class="col-span-1 lg:col-span-2 mt-8 ">
        <div class="card">
            <h5>Les derniers restaurants ajoutés</h5>
            <DataTable :value="restaurant" :rows="5" :paginator="true" responsiveLayout="scroll">
                <Column style="width: 15%">
                    <template #header> Image </template>
                    <template #body="slotProps">
                        <OverlayPanel :ref="'op' + slotProps.index" style="width: 300px; height: 220px;">
                            <img :src="baseurl + slotProps.data.Images[0].image" alt="Bamboo Watch" />
                        </OverlayPanel>
                        <img v-if="slotProps.data.Images && slotProps.data.Images.length > 0 && slotProps.data.Images[0].image"
                            :src="baseurl + slotProps.data.Images[0].image" :alt="slotProps.data.name" width="50"
                            class="shadow-lg border rounded-md" @click="toggle($event, slotProps.index)" />
                        <img v-else :src="baseurl + '/Restaurants/defaultimg.png'" :alt="slotProps.data.name" width="50"
                            class="shadow-lg border rounded-md" @click="toggle($event, slotProps.index)" />
                    </template>
                </Column>
                <Column field="name" header="Name" :sortable="true" style="width: 15%"></Column>
                <Column field="address" header="Address" :sortable="true" style="width: 15%"></Column>
                <Column field="city" header="City" :sortable="true" style="width: 15%"></Column>
                <Column field="phone" header="Phone number" :sortable="true" style="width: 15%"></Column>

                <Column field="ownername" header="Owner name" :sortable="true" style="width: 15%"></Column>
                <Column field="created_at" header="Created at" :sortable="true" style="width: 15%"></Column>

                <Column header="Status">
                    <template #body="slotProps">
                        <Tag :severity="slotProps.data.status === 1 ? 'success' : slotProps.data.status === 0 ? 'warning':'danger'"
                            :value="slotProps.data.status === 1 ? 'Approved' : slotProps.data.status === 0 ? 'Pending' :'Rejected'" />
                    </template>
                </Column>





            </DataTable>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-8 mb-8 ">
            <div class="card" style="height: 300px;">
                <h5>Top 3 des restaurants les plus réservés</h5>

                <div class="card  flex justify-content-center" style="background-color:rgb(248 255 236) ;">
                    <Chart type="doughnut" :data="chartData" :options="chartOptions" class="w-full h-full md:w-30rem" />
                </div>

            </div>
            <div class="card flex flex-col item-center justify-between">
                <h5>Les 3 derniers review ajoutés</h5>
                <Panel v-for="review in reviews" :key="review.id" :header="'Review Created at ' + review.created_at"
                    toggleable class="mb-2" :expanded="false" collapsed>
                    <p>
                        <strong class="text-[#9EB23B]">{{ review.user_name }}</strong> added a review on <strong
                            class="text-[#9EB23B]">{{ review.restaurant_name
                            }}</strong> and gave a rating of <strong class="text-[#9EB23B]">{{ review.rating }}/5</strong>.
                    </p>
                    <p><strong>Comment:</strong> {{ review.comment }}</p>
                    <p><strong>Posted at:</strong> {{ review.created_at }}</p>

                </Panel>




            </div>
        </div>

    </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Button from 'primevue/button';
import Row from 'primevue/row';
import axios from 'axios';
import OverlayPanel from 'primevue/overlaypanel';
import Tag from 'primevue/tag';
import Chart from 'primevue/chart';
import Panel from 'primevue/panel';


















export default {
    components: {
        DataTable,
        Column,
        ColumnGroup,
        Row,
        Button,
        OverlayPanel,
        Tag,
        Chart,
        Panel,





    },

    data() {
        return {
            restaurant: [],
            baseurl: 'https://goodresa.com/images/',
            apiUrl: 'https://goodresa.com/api/Admin/getlastadded',
            apiToken: 'vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy',
            visible: true,
            totalbookinday: 0,
            totalbookingsincelastweek: 0,
            totaluser: 0,
            totalusersincelastweek: 0,
            totalrestaurant: 0,
            totalrestaurantlastweek: 0,
            totalreviewday: 0,
            totalreviewlastweek: 0,
            top3: [],
            reviews: [],
            toggleable: false


        };
    },
    mounted() {
        const token = localStorage.getItem('auth_token');


        console.log("haihatoken " + token)
        axios.get(this.apiUrl, {
            headers: {
                Authorization: `Bearer ${this.apiToken}`,
                'auth-token': token
            }
        })
            .then((response) => {
                this.restaurant = response.data.Restaurants.Restaurants
                this.totalbookinday = response.data.Restaurants.totalBookings
                this.totalbookingsincelastweek = response.data.Restaurants.totalbookingslastweek
                this.totaluser = response.data.Restaurants.totalUsers
                this.totalusersincelastweek = response.data.Restaurants.totalNewUsersLastWeek
                this.top3 = response.data.Restaurants.top3;
                this.reviews = response.data.Restaurants.last_3_review;
                this.totalrestaurant = response.data.Restaurants.totalrestaurant
                this.totalrestaurantlastweek = response.data.Restaurants.totalnewrestaurant
                this.totalreviewday = response.data.Restaurants.totalreviewthisday
                this.totalreviewlastweek = response.data.Restaurants.totalreviewlastweek

            })
            .catch(error => {
                console.log(error)
            })
    },
    methods: {
        toggle(event, index) {
            const overlayRef = this.$refs['op' + index];
            overlayRef.toggle(event);
        },


    },
    computed: {
        chartData() {
            return {
                labels: this.top3.map((item) => item.name),
                datasets: [
                    {
                        data: this.top3.map((item) => item.total_bookings),
                        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                    },
                ],
            };
        },
        chartOptions() {
            return {
                responsive: true,
                maintainAspectRatio: false,
            };
        },

    },
    setup() {

    },
};
</script>


<style scoped>
.card {
    margin-bottom: 0rem !important
}
</style>