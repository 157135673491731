<template>
    <div class="grid grid-cols-2 gap-8 mx-96 my-20 card">
      <div v-if="!requestSent">
        <!-- Profile Form -->
        <div>
          <h2 class="text-[#9EB23B] text-4xl font-bold mb-4">User Profile</h2>
            <div class="mb-4">
                <span class="p-input ">
              <InputText v-model="name" placeholder="Name" class="w-full p-3 border rounded" />
            </span>
            </div>
            <div class="mb-4">

            <span class="p-input ">
              <InputText v-model="email" placeholder="Email" class="w-full p-3 border rounded mb-4" />
            </span>
            </div>
            <div class="mb-4">

          <span class="p-input">
            <InputText v-model="phone" placeholder="Phone" class="w-full p-3 border rounded" />
          </span>
          </div>
          <!-- Add more fields as needed -->
  
          <div class="flex justify-center mt-6">
            <ProgressSpinner v-if="visible" style="width: 100%; height: 50px;" strokeWidth="3" animationDuration=".5s"
              aria-label="Loading" />
            <button v-if="!visible" @click="updateProfile"
              class="bg-[#9EB23B] hover:bg-[#7E9727] text-white font-bold py-3 px-6 rounded-full w-full">
              Update Profile
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <!-- Request Sent Message -->
        <div class="flex flex-col items-center justify-center mt-20">
          <h2 class="text-[#9EB23B] text-4xl font-bold mb-4">Request Sent</h2>
          <p class="text-gray-600 mb-10 text-center text-xl">
            Your request to update your profile has been submitted. Our team will review it, and you will receive a
            response via email soon.
          </p>
          <!-- Add more elements or links if needed -->
        </div>
      </div>
      <div>
        <img src="../assets/Waiters-amico.svg" alt="" class="w-full h-auto">
      </div>
    </div>
    <Toast />
  </template>
  
  <script>
  import InputText from 'primevue/inputtext';
  import ProgressSpinner from 'primevue/progressspinner';
  import Toast from 'primevue/toast';
  import axios from 'axios';
  
  export default {
    components: {
      InputText,
      ProgressSpinner,
      Toast
    },
    data() {
      return {
        apiToken: 'vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy',
        apiUrl: 'https://goodresa.com/api/User/profile',
        visible: false,
        name: '',
        email: '',
        phone: '',
        // Add more fields as needed
        requestSent: false
      };
    },
    created() {
      // Fetch user profile data when the component is created
      this.fetchUserProfile();
    },
    methods: {
      fetchUserProfile() {
        const token = localStorage.getItem('auth_token');
        const config = {
          headers: {
            Authorization: `Bearer ${this.apiToken}`,
            'auth-token': token
          },
        };
  
        axios
          .post(this.apiUrl, null, { headers: config.headers })
          .then((response) => {
            this.name = response.data.name;
            this.email = response.data.email;
            this.phone = response.data.phone;
            // Set other fields accordingly
          })
          .catch((error) => {
            console.log(error);
          });
      },
      updateUserProfile() {
      this.visible = true;

      // Prepare the request data (update as needed)
      const requestData = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        // Add other fields to be updated
      };

      const token = localStorage.getItem('auth_token');
      const config = {
        headers: {
          Authorization: `Bearer ${this.apiToken}`,
          'auth-token': token,
        },
      };

      axios
        .put(this.apiUrl, requestData, { headers: config.headers })
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'User profile updated successfully.',
              life: 3000,
            });
            this.visible = false;
            // If you need to update other fields in the Vue component, do it here
          } else {
            this.visible = false;
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to update user profile.',
              life: 3000,
            });
          }
        })
        .catch((error) => {
          this.visible = false;
          console.error('Axios Error:', error);
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred while updating user profile.',
            life: 3000,
          });
        });
    },

    }
  };
  </script>
  
  <style scoped>
  /* ... existing styles ... */
  
  .animate-bounce {
    animation: bounce 1s infinite;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(-20%);
    }
    50% {
      transform: translateY(0);
    }
  }
  </style>
  