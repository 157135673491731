<template>
  <div class="card">
    <DataTable v-model:expandedRows="expandedRows" v-model:filters="filters" :value="users" dataKey="id"
      @rowExpand="onRowExpand" :rows="10" :paginator="true" responsiveLayout="scroll" @rowCollapse="onRowCollapse"
      tableStyle="min-width: 60rem">
      <template #header>
        <div class="flex justify-content-end">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Keyword Search" style="margin-right: 8px;" />
          </span>
        </div>
      </template>
      <Column expander style="width: 5rem" />
      <Column field="name" header="Name" :sortable="true">
        <template #body="slotProps">
          <!-- <Avatar image="/images/avatar/asiyajavayant.png" class="mr-2" size="large" shape="circle" /> -->
          <Avatar icon="pi pi-user" class="mr-2" size="large" shape="circle" />

          {{ slotProps.data.name }}

        </template>

      </Column>
      <Column field="email" header="Email" style="width: 15%" :sortable="true"></Column>
      <Column field="phone" header="Phone" style="width: 15%" :sortable="true"></Column>
      <Column field="created_at" header="Created at" style="width: 15%" :sortable="true"></Column>
      <Column header="Role" style="width: 15%">
        <template #body="slotProps">
          <Tag
            :severity="slotProps.data.role === 'admin' ? 'success' : slotProps.data.role === 'customer' ? 'info' : 'warning'"
            :value="slotProps.data.role === 'owner' ? 'Restaurant owner' : slotProps.data.role === 'customer' ? 'User Customer' : 'Administrator'" />
        </template>
      </Column>

      <template #expansion="slotProps">
        <div class="p-3">
          <h5>Reviews for {{ slotProps.data.name }}</h5>
          <DataTable :value="slotProps.data.Reviews">
            <Column header="Rating">
              <template #body="slotProps">
                <Rating :modelValue="Math.round(slotProps.data.rating)" readonly :cancel="false">
                </Rating>

              </template>
            </Column>
            <Column field="restaurant_name" header="Restaurant Name"></Column>
            <Column field="comment" header="Comment"></Column>
            <Column header="Classification">
              <template #body="slotProps">
                <Tag :severity="checkcomment(slotProps.data.comment) === 'Respectful' ? 'success' : 'warning'"
                  :value="checkcomment(slotProps.data.comment)" />
              </template>
            </Column>
            <Column field="created_at" header="Created At"></Column>
            <Column field="updated_at" header="Updated At"></Column>

            <Column header="Status">
              <template #body="slotProps">
                <Button :icon="slotProps.data.status === 1 ? 'pi pi-eye' : 'pi pi-eye-slash'"
                  @click="() => confirm1(slotProps)" severity="primary" text rounded aria-label="View Details" />
                <!-- <i :class="slotProps.data.status === 1 ? 'pi pi-eye' : 'pi pi-eye-slash'" style="color: #6366F1;"></i> -->
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
    <Toast />
    <ConfirmDialog></ConfirmDialog>

  </div>
</template>
  
<script>
import { ref, onMounted } from 'vue';
import { useToast } from 'primevue/usetoast';
import axios from 'axios';
import Toast from 'primevue/toast';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Avatar from 'primevue/avatar';
import Tag from 'primevue/tag';
import Rating from 'primevue/rating';
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from 'primevue/confirmdialog';





export default {
  components: {
    DataTable,
    Column,
    InputText,
    Toast,
    Avatar,
    Tag,
    Rating,
    ConfirmDialog
  },
  methods: {
    checkcomment(Comment) {
      const disrespectfulWords = ['fuck', 'hate', 'disgusting', 'awful', 'terrible', 'disgusting', 'worst', 'gross', 'rude', 'inappropriate', 'unacceptable', 'shit']; // Add more disrespectful words as needed

      for (const word of disrespectfulWords) {
        if (Comment.toLowerCase().includes(word)) {
          return 'Not Respectful';
        }
      }

      return 'Respectful';

    }

  },

  setup() {
    const users = ref([]);
    const expandedRows = ref([]);
    const confirm = useConfirm();
    let changeStatusResponse = null;

    const toast = useToast();
    const filters = ref({
      global: { value: null },
    });

    onMounted(() => {
      getUsers();
    });

    const getUsers = async () => {
      try {
        const response = await axios.get('https://goodresa.com/api/Admin/getuserdata', {
          headers: {
            Authorization: `Bearer vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy`,
            'auth-token': localStorage.getItem('auth_token'),
          },
        });

        users.value = response.data.Users;
      } catch (error) {
        console.log(error);
      }
    };

    const onRowExpand = (event) => {
      toast.add({ severity: 'info', summary: 'User Expanded', detail: event.data.name, life: 3000 });
    };

    const onRowCollapse = (event) => {
      toast.add({ severity: 'success', summary: 'User Collapsed', detail: event.data.name, life: 3000 });
    };

    const expandAll = () => {
      expandedRows.value = users.value.map((user) => user.id);
    };

    const collapseAll = () => {
      expandedRows.value = [];
    };
    const confirm1 = (slotProps) => {
      const { data } = slotProps;

      confirm.require({
        message: 'Are you sure you want to change the visibility of this restaurant ?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          console.log('Accepted confirmation for ID:', data.id);
          ChangeStatus(data.id, data.status===1?0:1)
            .then((response) => {
              changeStatusResponse = response;
              console.log(changeStatusResponse);


              if (changeStatusResponse && changeStatusResponse.status === true) {
                data.status = data.status===1?0:1

                toast.add({ severity: 'info', summary: 'Confirmed', detail: 'The Status has been updated', life: 500 });
              }
            });


        },
      });
    };
    const ChangeStatus = (id, status) => {
      const url = `https://goodresa.com/api/Admin/change-Review-Status/${id}/${status}`;
      const token = localStorage.getItem('auth_token');
      const apiToken = 'vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy';
      const config = {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          'auth-token': token,
        },
      };



      return axios

        .post(url, null, { headers: config.headers })
        .then((response) => {
          return response.data;
        })

        .catch((error) => {
          console.log(error);
        });
    };

    return {
      users,
      expandedRows,
      toast,
      filters,
      onRowExpand,
      onRowCollapse,
      expandAll,
      collapseAll,
      confirm1,
      changeStatusResponse
    };
  },
};
</script>
  