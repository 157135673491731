<template>
    <footer aria-label="Site Footer" class="bg-[#F2F6F4]">
        <div class=" max-w-screen-2xl px-4 py-16 mx-auto space-y-8 sm:px-6 lg:space-y-16 lg:px-8">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-8 lg:grid-cols-3 md:justify-items-center sm:justify-items-start">
                <div>
                    <div class="text-teal-600">
                        <img src="../assets/MiamDeallogo.svg" />
                    </div>

                    <p class="max-w-xs mt-4 text-gray-500">
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse non
                        cupiditate quae nam molestias.
                    </p>

                    <ul class="flex gap-6 mt-8">
                        <li>
                            <a href="/" rel="noreferrer" target="_blank" class="text-gray-700 transition hover:opacity-75">
                                <span class="sr-only">Facebook</span>

                                <font-awesome-icon class="text-3xl text-[#9EB23B]" icon="fa-brands fa-facebook" />
                            </a>
                        </li>

                        <li>
                            <a href="/" rel="noreferrer" target="_blank" class="text-gray-700 transition hover:opacity-75">
                                <span class="sr-only">Instagram</span>

                                <font-awesome-icon class="text-3xl text-[#9EB23B]" icon="fa-brands fa-instagram" />
                            </a>
                        </li>

                        <li>
                            <a href="/" rel="noreferrer" target="_blank" class="text-gray-700 transition hover:opacity-75">
                                <span class="sr-only">Twitter</span>

                                <font-awesome-icon class="text-3xl text-[#9EB23B]" icon="fa-brands fa-twitter" />
                            </a>
                        </li>


                    </ul>
                </div>

                <div>
                    <p class="font-medium text-[#819619] text-xl">Liens utiles</p>

                    <nav aria-label="Footer Navigation - Company" class="mt-6">
                        <ul class="space-y-4 text-sm">
                            <li>
                                <a href="#" class="text-gray-700 transition hover:opacity-75">
                                    Contact
                                </a>
                            </li>

                            <li>
                                <a href="#" class="text-gray-700 transition hover:opacity-75">
                                    Programmes de fidélité
                                </a>
                            </li>

                            <li>
                                <a href="#" class="text-gray-700 transition hover:opacity-75">
                                    Etes-vous un restaurant?
                                </a>
                            </li>
                            <li>
                                <a href="#" class="text-gray-700 transition hover:opacity-75">
                                    Nous embauchons
                                </a>
                            </li>
                            <li>
                                <a href="#" class="text-gray-700 transition hover:opacity-75">
                                    Consitions d'utilisations
                                </a>
                            </li>
                            <li>
                                <a href="#" class="text-gray-700 transition hover:opacity-75">
                                    Confidentialité
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>

                <div>
                    <p class="font-medium text-[#819619] text-xl">Newsletter</p>

                    <form class="flex flex-col gap-2 mt-6">
                        <input class="px-4 py-2 rounded text-xl" type="email" name="" id="" placeholder="Entrez votre email">
                        <button class="bg-[#819619] rounded text-white py-1 text-lg" type="submit">Inscrivez-vous</button>
                    </form>
                </div>
            </div>
            <p class="text-ms text-gray-500 text-center">
                Copyright &copy; 2023. MiamDeal. Tous droits réservés.
            </p>
            
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style scoped></style>