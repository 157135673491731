<template>
    <div class=" mx-auto max-w-screen-xl px-2 py-4 mt-4">
        <div class="flex flex-row justify-between mb-6 px-5">
            <h1 class="text-3xl text-black font-normal">Où puis-je gagner plus de Yums ?</h1>
            <div class="flex flex-row gap-2">
                <button class="button-previous bg-gray-100 rounded-md">
                    <font-awesome-icon class=" text-gray-600 px-2 py-0" icon="fa-solid fa-chevron-left" />
                </button>
                <button class="button-nextone bg-gray-100   rounded-md">
                    <font-awesome-icon class=" text-gray-600 px-2 py-0" icon="fa-solid fa-chevron-right" />
                </button>
            </div>
        </div>

        <swiper :modules="modules" grab-cursor:true :slides-per-view="1" :breakpoints="{
                '640': {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                '768': {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                '1024': {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
            }" :pagination="{ el: '.swiper-pagination', clickable: true }" :navigation="{
            nextEl: '.button-nextone',
            prevEl: '.button-previous',
            clickable: true,
        }"  class="swiper_container">

            <swiper-slide v-for="card in cards">
                <a :href="card.url">
                    <div class="text-left rounded-lg p-5 space-y-2 relative">
                        <img class="h-50 w-full object-cover rounded-md imgres" :src="card.image" alt="" style="width: 361.319px; height: 237.951px;">
                        <div class="absolute -top-1 right-0">
                            <font-awesome-icon class="fa-3x text-[#4CD964]" icon="fa-solid fa-star" />
                            <span class="absolute top-3 right-[15px] text-white text-lg font-medium ">{{ card.score
                            }}</span>
                        </div>
                        <p class="text-black font-thin italic text-xl uppercase">{{ card.specialité }}</p>
                        <p class="mt-2 text-2xl font-semibold text-black">{{ card.title }}</p>
                        <p class="mt-2 mb-4 font-normal text-xl text-black max-w-[150px]">{{ card.description }}</p>
                        <div>
                            <span v-for="tag in card.tags"
                                class="mr-2 text-white px-4 py-1 rounded-lg  uppercase text-md font-light"
                                :style="{ 'background-color': tag.color }">{{ tag.name }}</span>
                        </div>

                        <p class="text-[#9EB23B] font-semibold text-2xl">{{ card.prix }}<span
                                class="ml-1 mr-2">dh</span><span class="font-normal text-lg">{{ card.typePrix }}</span></p>
                        <p class="text-white bg-[#9EB23B] p-1 rounded-lg max-w-min">{{ card.remise }}</p>
                    </div>
                </a>

            </swiper-slide>


        </swiper>
    </div>
</template>
  
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation } from "swiper";
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";


export default {
    data() {
        return {
            cards: [
                {
                    specialité: "italien avec des amis",
                    score: "9.1",
                    image: require('../assets/img_7.png'),
                    title: "Agricolo Toscana",
                    description: "Lorem ipsum dolor sit amet",
                    prix: 360,
                    typePrix: "Prix moyen",
                    remise: "-20%",
                    url: "https://google.com",
                    tags: [
                        {
                            name: "yums x2",
                            color: "#819619"
                        }, 
                        {
                            name: "intié",
                            color: "#489619"
                        },
                        {
                            name: "payer",
                            color: "#199652"
                        }
                    ]
                },
                {
                    specialité: "italien romantic",
                    score: "9.2",
                    image: require('../assets/img_8.png'),
                    title: "La Buona Novella",
                    description: "Lorem ipsum dolor sit amet",
                    prix: 500,
                    typePrix: "Prix moyen",
                    remise: "-30%",
                    url: "https://google.com",
                    tags: [
                        {
                            name: "yums x2",
                            color: "#819619"
                        }, 
                        {
                            name: "intié",
                            color: "#489619"
                        },
                        
                    ]
                },
                {
                    specialité: "Murales bistro",
                    score: "9.3",
                    image: require('../assets/img_9.png'),
                    title: "Ristorante Storico",
                    description: "Lorem ipsum dolor sit amet",
                    prix: 570,
                    typePrix: "Prix moyen",
                    remise: "-20%",
                    url: "https://google.com",
                    tags: [
                        {
                            name: "yums x2",
                            color: "#819619"
                        }, 
                        {
                            name: "intié",
                            color: "#489619"
                        },
                        {
                            name: "payer",
                            color: "#199652"
                        }
                    ]
                },
                {
                    specialité: "italien avec des amis",
                    score: "9.1",
                    image: require('../assets/img_7.png'),
                    title: "Agricolo Toscana",
                    description: "Lorem ipsum dolor sit amet",
                    prix: 360,
                    typePrix: "Prix moyen",
                    remise: "-20%",
                    url: "https://google.com",
                    tags: [
                        {
                            name: "yums x2",
                            color: "#819619"
                        }, 
                        {
                            name: "intié",
                            color: "#489619"
                        },
                        {
                            name: "payer",
                            color: "#199652"
                        }
                    ]
                },
                {
                    specialité: "italien romantic",
                    score: "9.2",
                    image: require('../assets/img_8.png'),
                    title: "La Buona Novella",
                    description: "Lorem ipsum dolor sit amet",
                    prix: 500,
                    typePrix: "Prix moyen",
                    remise: "-30%",
                    url: "https://google.com",
                    tags: [
                        {
                            name: "yums x2",
                            color: "#819619"
                        }, 
                        {
                            name: "intié",
                            color: "#489619"
                        },
                        
                    ]
                },
                {
                    specialité: "Murales bistro",
                    score: "9.3",
                    image: require('../assets/img_9.png'),
                    title: "Ristorante Storico",
                    description: "Lorem ipsum dolor sit amet",
                    prix: 570,
                    typePrix: "Prix moyen",
                    remise: "-20%",
                    url: "https://google.com",
                    tags: [
                        {
                            name: "yums x2",
                            color: "#819619"
                        }, 
                        {
                            name: "intié",
                            color: "#489619"
                        },
                        {
                            name: "payer",
                            color: "#199652"
                        }
                    ]
                }
            ]
        }
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        

        return {
            
            Swiper,
            modules: [Navigation, Pagination],

        };
    },
};
</script>
  
<style scoped>
@media (max-width: 767px) {
  .imgres {
    width: 100% !important;
    height: auto !important;
  }
}
</style>
  