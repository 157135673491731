<template>
    <div class=" mx-auto max-w-screen-xl px-2 py-4 mt-4">
        <div class="flex flex-row justify-between mb-6 px-5">
            <h1 class="text-3xl text-black font-normal">Restaurants choisis pour vous</h1>
            <div class="flex flex-row gap-2">
                <button class="btn-prev bg-gray-100 rounded-md">
                    <font-awesome-icon class=" text-gray-600 px-2 py-0" icon="fa-solid fa-chevron-left" />
                </button>
                <button class="btn-next bg-gray-100   rounded-md">
                    <font-awesome-icon class=" text-gray-600 px-2 py-0" icon="fa-solid fa-chevron-right" />
                </button>
            </div>
        </div>

        <div v-if="isLoading">
            <div class="flex flex-row gap-12 justify-between">
                <div v-for="index in skeletonCount" :key="index" class="w-full p-4 rounded shadow animate-pulse ">
                    <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded ">
                        <svg class="w-12 h-12 text-gray-200 dark:text-gray-600" xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true" fill="currentColor" viewBox="0 0 640 512">
                            <path
                                d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                        </svg>
                    </div>
                    <div class="h-2.5 bg-gray-200 rounded-full  w-48 mb-4"></div>
                    <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
                    <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
                    <div class="h-2 bg-gray-200 rounded-full "></div>
                </div>

            </div>

        </div>

        <div v-else>
            <swiper :modules="modules" grab-cursor:true :slides-per-view="1" :breakpoints="{
                '640': {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                '768': {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                '1024': {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
            }" :pagination="{ el: '.swiper-pagination', clickable: true }" :navigation="{
    nextEl: '.btn-next',
    prevEl: '.btn-prev',
    clickable: true,
}" class="swiper_container">

                <swiper-slide v-for="card in sortedRestaurants">
                    <router-link :to="`/restaurants/${card.id}`">
                        <div class="text-left rounded-lg p-5 space-y-1 relative">
                            <img class=" object-cover rounded-md imgres"
                                v-if="card.Images && card.Images.length > 0 && card.Images[0].image"
                                :src="baseurl + card.Images[0].image" alt="" style="width: 361.319px; height: 237.951px;"  >
                            <img class="h-50 w-full object-cover rounded-md" v-else
                                src="https://goodresa.com/images/Restaurants/646669f354d3d.jpg" alt="Default Image">
                            <div class="absolute top-0 right-0">
                                <font-awesome-icon class="fa-3x text-[#4CD964]" icon="fa-solid fa-star" />
                                <span class="absolute top-3 right-[15px] text-white text-lg font-medium ">{{ card.avg_rating
                                }}</span>
                            </div>
                            <p class="text-black font-thin italic text-xl uppercase"
                                v-if="card.categories_restaurant && card.categories_restaurant.length > 0 && card.categories_restaurant[0].category_name">
                                {{ card.categories_restaurant[0].category_name }}</p>
                            <p class="text-black font-thin italic text-xl uppercase" v-else>Moroccan</p>
                            <p class="mt-2 text-2xl font-semibold text-black">{{ card.name }}</p>
                            <p class="mt-2 mb-4 font-normal text-xl text-black max-w-[150px]"><span>{{ card.address
                            }}</span><br /><span>{{ card.city }}</span></p>
                            <p class="text-[#9EB23B] font-semibold text-2xl" v-if="card.avg_price">{{ card.avg_price }}<span
                                    class="ml-1 mr-2">dh</span><span class="font-normal text-lg">Prix moyen</span></p>
                            <p class="text-white bg-[#9EB23B] p-1 rounded-lg max-w-min">20%</p>
                        </div>
                    </router-link>

                </swiper-slide>


            </swiper>
        </div>


    </div>
</template>
  
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation } from "swiper";
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from 'axios';



export default {
    data() {
        return {
            baseurl: 'https://goodresa.com/images/',
            apiUrl: 'https://goodresa.com/api/Restaurant/get-main-Restaurants',
            apiToken: 'vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy',
            cards: [],
            isLoading: true,
            skeletonCount: 3
        }
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    computed: {
        sortedRestaurants() {
            return this.cards.slice().sort((a, b) => b.avg_rating - a.avg_rating).slice(0, 5); // Get the first 5 elements

        }
    },
    async mounted() {
        await axios.get(this.apiUrl, {
            headers: {
                Authorization: `Bearer ${this.apiToken}`
            }
        })
            .then((response) => {
                this.cards = response.data.Restaurants;
                this.isLoading = false
            })
            .catch(error => {
                console.log(error);
                this.isLoading = false
            })
    },
    setup() {


        return {
            Swiper,
            modules: [Navigation, Pagination],


        };
    },
};
</script>
  
<style scoped>
@media (max-width: 767px) {
  .imgres {
    width: 100% !important;
    height: auto !important;
  }
}
</style>
