<template  >
    <div class="card">
        <div class=" flex justify-center items-center">
            <h1 class="mb-1  ">{{ restaurant.name }}</h1>

        </div>
        <div class=" flex justify-center items-center">

            <Carousel :value="restaurant.Images" :numVisible="2" :numScroll="2" :responsiveOptions="responsiveOptions">
                <template #item="{ data }">
                    <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                        <div class="mb-3">
                            <img v-if="data.image" :src="baseurl + data.image" class="shadow-lg border rounded-md"
                                style="width: 361.319px; height: 200px;" />
                        </div>

                    </div>
                </template>
            </Carousel>
        </div>
        <div class="card">
            <TabView class="tabview-custom">
                <TabPanel>
                    <template #header>
                        <i class="pi pi-calendar mr-2"></i>
                        <span>About</span>
                    </template>
                    <div>
                        <h1 class="text-[#9EB23B] text-3xl font-semibold mb-4">{{ restaurant.name }}</h1>
                        <div v-if="restaurant.avg_rating" class="bg-[#FAFBFC] py-4 px-6 rounded-lg shadow-lg mb-4">
                            <div class="flex items-center">
                                <i class="pi pi-star text-yellow-500 mr-2"></i>
                                <p class="text-lg text-gray-500"><strong>Avg Rating:</strong> {{
                                    parseFloat(restaurant.avg_rating).toFixed(2) }}</p>
                            </div>
                        </div>
                        <div v-if="restaurant.address" class="bg-[#FAFBFC] py-4 px-6 rounded-lg shadow-lg mb-4">
                            <p class="text-lg text-gray-500">
                                <i class="pi pi-home text-gray-500 mr-2"></i>
                                <span><strong>Address:</strong> {{ restaurant.address }}, {{ restaurant.city }}, {{
                                    restaurant.country }}</span>
                            </p>
                        </div>
                        <div v-if="restaurant.phone" class="bg-[#FAFBFC] py-4 px-6 rounded-lg shadow-lg mb-4">
                            <p class="text-lg text-gray-500">
                                <i class="pi pi-phone text-gray-500 mr-2"></i>
                                <span><strong>Phone:</strong> {{ restaurant.phone }}</span>
                            </p>
                        </div>
                        <div v-if="restaurant.website" class="bg-[#FAFBFC] py-4 px-6 rounded-lg shadow-lg mb-4">
                            <p class="text-lg text-gray-500">
                                <i class="pi pi-external-link text-gray-500 mr-2"></i>
                                <span><strong>Website:</strong> <a :href="restaurant.website"
                                        class="text-[#9EB23B] hover:underline">{{ restaurant.website }}</a></span>
                            </p>
                        </div>
                        <div v-if="restaurant.categories_restaurant && restaurant.categories_restaurant.length > 0"
                            class="bg-[#FAFBFC] py-4 px-6 rounded-lg shadow-lg mb-4">
                            <p class="text-lg text-gray-500 mb-4">
                                <strong>Category:</strong> {{ restaurant.categories_restaurant[0].category_name }}
                            </p>
                        </div>
                        <div v-if="restaurant.Description" class="bg-[#FAFBFC] py-4 px-6 rounded-lg shadow-lg mb-4">
                            <p class="text-lg text-gray-700"><strong>Description:</strong> {{ restaurant.Description }}</p>
                        </div>
                    </div>







                </TabPanel>
                <TabPanel>
                    <template #header>
                        <i class="pi pi-calendar mr-2"></i>
                        <span>Menu</span>
                    </template>
                    <div v-if="menuItems" v-for="category in menuItems" :key="category.id_category" class="my-6">
                        <h3 class="text-lg font-semibold mb-2">{{ category.category_name }}</h3>
                        <div v-for="item in category.items" :key="item.id" class="flex justify-between mb-2">
                            <div>
                                <p class="font-semibold">{{ item.name }}</p>
                                <p class="text-gray-500 max-w-sm">{{ item.description }}</p>
                            </div>
                            <p class="font-semibold">{{ item.price }}</p>
                        </div>
                        <div class="border-t border-gray-300 my-4"></div>

                    </div>
                </TabPanel>
                <TabPanel>
                    <template #header>
                        <span>Reviews</span>
                        <i class="pi pi-user ml-2"></i>
                    </template>
                    <div class="flex justify-center items-center">
                        <div class="card flex items-center">
                            <Knob :modelValue="Math.round(restaurant.avg_rating)" min="0" :max="5" readonly />
                            <div class="flex flex-col items-center ml-2">
                                <p class="font-semibold text-lg text-gray-700">Average Rating</p>
                                <p class="font-semibold text-sm text-gray-500"> {{ reviews.length }} Reviews</p>
                            </div>
                        </div>
                    </div>
                    <div v-for="review in reviews" :key="review.id">
                        <div class="flex items-center justify-between mb-2">
                            <div class="flex items-center">
                                <img src="https://w7.pngwing.com/pngs/481/915/png-transparent-computer-icons-user-avatar-woman-avatar-computer-business-conversation-thumbnail.png"
                                    alt="User Avatar" class="w-8 h-8 rounded-full mr-2">
                                <div>
                                    <p class="font-semibold">User</p>
                                    <p class="text-xs">{{ review.created_at }}</p>
                                </div>

                            </div>
                            <div class="flex items-center">
                                <Rating :modelValue="Math.round(review.rating)" readonly :cancel="false">
                                </Rating>

                            </div>
                        </div>
                        <p class="text-gray-500">{{ review.comment }}</p>
                        <div class="border-t border-gray-300 my-4"></div>

                    </div>
                </TabPanel>
                <TabPanel>
                    <template #header>
                        <span>Offre</span>
                        <i class="pi pi-cog ml-2"></i>
                    </template>
                    <div v-for="offre in Offres" class="card bg-gray-100 py-4 px-6 rounded-lg shadow-lg">
                        <div class="flex justify-between items-center mb-2">
                            <p class="text-white bg-[#9EB23B] py-1 px-2 rounded-lg inline-block">
                                -{{ offre.discount_value }}{{ offre.discount_type === 'fixed' ? ' DH' : '%' }}
                            </p>
                            <p class="font-semibold text-sm text-gray-700 ml-2">{{ offre.discount_type }}</p>
                        </div>
                        <p class="text-sm text-gray-500 mb-1">
                            <i class="pi pi-calendar mr-1"></i>
                            <strong>Valid</strong> from {{ formatDate(offre.start_date) }} to {{ formatDate(offre.end_date)
                            }}
                        </p>
                        <p class="text-sm text-gray-500 mb-1">
                            <i class="pi pi-check-circle mr-1"></i>
                            <strong>Max Uses:</strong> {{ offre.max_uses !== null ? offre.max_uses : 'Unlimited' }}
                        </p>
                    </div>




                </TabPanel>
            </TabView>

        </div>

    </div>
</template>
  


<script>
;
import axios from 'axios';

import Carousel from 'primevue/carousel';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Rating from 'primevue/rating';
import Knob from 'primevue/knob';





export default {

    data() {
        return {
            restaurant: [],
            baseurl: 'https://goodresa.com/images/',
            apiUrl: 'https://goodresa.com/api/Restaurant',
            apiToken: 'vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy',
            menuItems: [],
            skeletonCount: 5,
            isLoading: true,
            reviews: [],
            date: null,
            time: null,
            value: 0,
            isauth: false,
            averageRating: 4.5,
            ratings: [
                { value: 5, percent: '80%' },
                { value: 4, percent: '60%' },
                { value: 3, percent: '40%' },
                { value: 2, percent: '20%' },
                { value: 1, percent: '50%' },
            ],
            Offres: null,
            sprequest: null,

        };
    },

    mounted() {
        const token = localStorage.getItem('token');
        const id = localStorage.getItem('iddetails');

        console.log('hahowa lid mn deatils daba', id);

        axios
            .get(`${this.apiUrl}/get-details-Restaurant/${id}`, {
                headers: {
                    Authorization: `Bearer ${this.apiToken}`,
                },
            })
            .then((response) => {
                console.log(response.data.Restaurants.Images);
                this.restaurant = response.data.Restaurants;
                console.log(response.data.Restaurants);
                if (response.data.Restaurants.Reviews) {
                    this.reviews = response.data.Restaurants.Reviews;
                } else {
                    this.reviews = [];
                }
                if (response.data.Restaurants.Offres) {
                    this.Offres = response.data.Restaurants.Offres;
                } else {
                    this.Offres = [];
                }
                if (response.data.Restaurants.Menu && response.data.Restaurants.Menu.Menu_Items) {
                    this.menuItems = response.data.Restaurants.Menu.Menu_Items;
                } else {
                    this.menuItems = [];
                }


                this.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
                this.isLoading = false;
            });
    },
    methods: {
        formatPrice(price) {
            return parseFloat(price).toFixed(2);
        },
        formatDate(date) {
            const formattedDate = new Date(date);
            const year = formattedDate.getFullYear();
            const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
            const day = formattedDate.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
    },
    components: {
        Carousel,
        TabView,
        TabPanel,
        Rating,
        Knob


    },
    setup() { },
};
</script>