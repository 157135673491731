import axios from 'axios';

export default{
  namespaced: true,
  state() {
    return {
      apiUrl: 'https://goodresa.com/api/Restaurant/get-main-Restaurants',
      apiToken: 'vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy',
      restaurants: [],
    };
  },
  mutations: {
    setRestaurants(state, restaurants) {
      state.restaurants = restaurants;
    },
  },
  actions: {
    async getData({ commit }) {
      try {
        const response = await axios.get(this.apiUrl,{
          headers: {
              Authorization: `Bearer ${this.apiToken}`,
            },
        });
        console.log('data :'+response.data)
        commit('setRestaurants',response.data.Restaurants);
        return this.state.restaurants;
      } catch (error) {
        console.error('Error fetching restaurants:', error);
      }
    },
   
  },
  getters: {
    topRatedRestaurants(state) {
      return state.restaurants.sort((a, b) => b.avg_rating - a.avg_rating).slice(0, 5);
    },
  },
}
    
