<template>
  <header class="sticky top-0 bg-white z-50 shadow-md">
    <div class="max-w-screen-2xl   mx-auto px-8 sm:px-8 md:px-6 lg:px-12"
      :class="{ 'py-6': !scrolled, 'py-3': scrolled }">
      <div class="grid grid-cols-2 md:grid-cols-3   gap-0 md:gap-4 justify-items-center ">
        <RouterLink to="/" class="flex items-center">
          <div class="max-w-full flex ">
            <img src="../assets/MiamDeallogo.svg" alt="Logo" class="w-full max-w-full">
          </div>
        </RouterLink>

        <div class="hidden md:block">

        </div>
        <div class="flex flex-row">
          <button @click="visible = true"
            class="flex items-center justify-center p-1 md:p-2 rounded-md ml-2 bg-transparent text-black font-semibold text-base md:text-lg">
            <font-awesome-icon class="icon text-[#9EB23B]" icon="fa-solid fa-mobile" />
            Application
          </button>
          <div v-if="loggedIn">
            <Button type="button" id="user-modal"
              style="background-color: white; color: #9EB23B;--tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
           --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow); border: 0px !important;"
              class="flex flex-row items-center p-2 pl-3 " @click="toggle" aria-haspopup="true"
              aria-controls="overlay_menu">
              <Avatar icon="pi pi-user" class="mr-2" size="large" shape="circle" />

              <div class="flex flex-col items-center">
                <span class="font-semibold text-black">{{ user.name }}</span>
                <span class="text-sm">{{ user.role }}</span>
              </div>
            </Button>
            <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
          </div>


          <button id="show-modal"
            class="flex items-center justify-center px-3 py-2 md:px-4 md:py-3 border-none rounded-md ml-2 bg-white border-2 border-green-500 text-[#9EB23B] font-semibold shadow-lg text-base md:text-lg"
            :class="{ 'md:py-3': !scrolled, 'md:py-1': scrolled }" @click="showModal = true" v-else>
            <font-awesome-icon class="icon text-[#9EB23B]" icon="fa-solid fa-user-large" />
            Compte
          </button>

          <Teleport to="body">
            <modal :show="showModal" @close="showModal = false" @open-login="openLoginModal">

            </modal>
            <modaltwo v-if="showLoginModal" @close="showLoginModal = false" @open-signup="openSignUpModal">

            </modaltwo>
          </Teleport>
        </div>
      </div>

      <DynamicDialog />

    </div>
  </header>
</template>

<script>

import Modal from './ModalSignUp.vue'
import Modaltwo from './ModalSignIn.vue'
import Bookingcard from './BookingCard.vue';

import { mapState, mapMutations, mapActions } from 'vuex';
import { useDialog } from 'primevue/usedialog';
import DynamicDialog from 'primevue/dynamicdialog';
import { ref } from 'vue';





export default {
  components: {
    Modal,
    Modaltwo,
    DynamicDialog
  },
  data() {
    return {
      scrolled: false,
      showModal: false,
      showLoginModal: false,
      visible: false,
      userName: "",
      userRole: "",
      items: [


        {
          label: 'Profile',
          icon: 'pi pi-user',
          command: () => {
            this.$toast.add({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
          }
        },
        {
          label: 'My Booking',
          icon: 'pi pi-user',
          command: () => {
            this.showRestaurantDetails();
          }
        },
        {
          label: 'Reglages',
          icon: 'pi pi-fw pi-cog',
          command: () => {
            this.$toast.add({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
          }
        },
        {
          label: 'Se déconnecter',
          icon: 'pi pi-sign-out',
          command: () => {
            this.logout()
          }
        }

      ]
    };
  },
  created() {
    const token = localStorage.getItem('auth_token');
    // console.log("token "+token)
    this.setLoggedIn(!!token);
    if (token) {
      this.$store.dispatch('authusers/loadUser'); // Load user object from local storage
    }


    if (this.loggedIn && this.user.role !== 'customer') {
      const dashboardItem = {
        label: 'Dashboard',
        icon: 'pi pi-fw pi-home',
        to: '/dashboard'
      };
      
      if (this.user.role == 'owner') {
        dashboardItem.to='/dashboard/ownerdashboard'


      }


      // Check if the "Dashboard" item already exists in the items array
      const existingDashboardItem = this.items.find(item => item.label === 'Dashboard');
      if (!existingDashboardItem) {
        this.items.splice(1, 0, dashboardItem);
      }
    } else {
      // Remove the "Dashboard" item from the items array if it exists
      const dashboardIndex = this.items.findIndex(item => item.label === 'Dashboard');
      if (dashboardIndex !== -1) {
        this.items.splice(dashboardIndex, 1);
      }
      const Registeritem = {
        label: 'Register your restaurant',
        icon: 'pi pi-fw pi-home',
        to: '/newrestaurant'
      };
      const existingDashboardItem = this.items.find(item => item.label === 'Register your restaurant');
      if (!existingDashboardItem) {
        this.items.splice(1, 0, Registeritem);
      }


    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);

  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 0;
    },
    openLoginModal() {
      this.showModal = false;
      this.showLoginModal = true;
    },
    openSignUpModal() {
      this.showModal = true;
      this.showLoginModal = false;
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    ...mapMutations('authusers', ['setLoggedIn']),
    ...mapActions('authusers', ['clearUser']),
    logout() {
      localStorage.removeItem('auth_token');
      this.clearUser();
      this.setLoggedIn(false);
    },

  },
  computed: {
    ...mapState('authusers', ['loggedIn'],),
    ...mapState('authusers', ['user'],),
  },
  setup() {
    const dialog = useDialog();
    const dialogVisible = ref(false);





    const showRestaurantDetails = () => {
      dialog.open(Bookingcard, {
        props: {
          style: {
            width: '55vw',
          },
          breakpoints: {
            '960px': '75vw',
            '640px': '90vw'
          },
          modal: true
        }
      });
    };




    return {
      showRestaurantDetails,
      dialogVisible
      // ... other returned values from the setup function
    };
  }



}




</script>

<style scoped>
.icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}
</style>