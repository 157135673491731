<script>
import axios from 'axios';
import ProgressSpinner from 'primevue/progressspinner';

export default {
  components: {
    ProgressSpinner,
  },
  props: {
    show: Boolean,
  },
  data() {
    return {
      name: '',
      phone: '',
      email: '',
      password: '',
      error: '',
      apiUrl: 'https://goodresa.com/api/User/create-user',
      apiToken: 'vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy',
      visible: false,
      showDialog: false,
      isSuccess: false,
      message: '', // The message to be displayed in the dialog
    };
  },
  methods: {
    Createuser() {
      this.visible = true;
      const config = {
        headers: {
          Authorization: `Bearer ${this.apiToken}`,
        },
      };

      const params = {
        email: this.email,
        password: this.password,
        name: this.name,
        phone: this.phone,
        role: 'customer',
      };

      axios
        .post(this.apiUrl, null, { params, headers: config.headers })
        .then((response) => {
          console.log(response.data.error_number);
          if (response.data.error_number === 0) {
            this.isSuccess = true;
            this.message = 'Registration Successful!';
          } 
          if(response.data.error_number !== 0) {
            this.isSuccess = false;
            //response.data.message ||
            this.message =  'Registration Failed!';
          }
          this.visible = false;
          this.showDialog = true;
          this.email = this.password = this.phone = this.name = this.error = '';
        })
        .catch((error) => {
          console.log(error);
          this.isSuccess = false;
          this.message = 'Registration Failed!';
          this.visible = false;
          this.showDialog = true;
        });
    },
  },
};
</script>


<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="h-fit bg-white px-5 py-16 rounded-xl m-auto relative" style="box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33); transition: all 0.3s ease;">
        <button class="absolute top-0 right-0" @click="$emit('close')">
          <img class="w-12 h-12" src="../assets/Xmark.svg" />
        </button>
        <!-- Left column container with background-->
        <div class="g-6 flex h-full flex-wrap items-center justify-center lg:justify-evenly">
          <div class="shrink-1 mb-12 grow-0 basis-auto md:mb-0 md:w-9/12 md:shrink-0 lg:w-6/12 xl:w-6/12">
            <img src="../assets/img_signup.png" class="w-96" alt="Sample image" />
          </div>

          <!-- Right column container -->
          <div class="mb-12 md:mb-0 md:w-8/12 lg:w-5/12 xl:w-5/12">
            <h1 class="mb-1 text-3xl font-bold text-center text-[#9EB23B]">Rejoignez-nous</h1>
            <p class="text-[#9D9D9D] mb-4 font-normal text-base text-center leading-tight"> Expérience personnalisée et avantages exclusifs. Inscrivez-vous dès aujourd'hui !</p>
            <input type="text" v-model="name" class="bg-[#F5F5F5] block border border-[#9EB23B] w-full p-3 rounded-lg placeholder:text-[#C0BEBE] mb-4" name="fullname" placeholder="Entrer votre nom complet" />

            <input type="text" v-model="email" class="bg-[#F5F5F5] block border border-[#9EB23B] w-full p-3 rounded-lg placeholder:text-[#C0BEBE] mb-4" name="email" placeholder="Entrer votre email" />

            <input type="password" v-model="password" class="bg-[#F5F5F5] block border border-[#9EB23B] w-full p-3 rounded-lg placeholder:text-[#C0BEBE] mb-4" name="password" placeholder="Entrer votre mot de passe" />

            <input type="tel" v-model="phone" class="bg-[#F5F5F5] block border border-[#9EB23B] w-full p-3 rounded-lg placeholder:text-[#C0BEBE] mb-4" name="telephone" placeholder="Entrer votre télephone" />

            <ProgressSpinner v-if="visible" class="flex align-items-center justify-content-center" style="width: 100%; height: 50px " strokeWidth="3" animationDuration=".5s" aria-label="Loading" />
            <button v-if="!visible" type="submit" @click="Createuser" class="w-full text-center py-3 rounded-lg bg-[#9EB23B] text-white text-xl font-bold  focus:outline-none my-1">S'inscrire</button>
            <p class="text-center text-[#9D9D9D] text-sm font-normal">Vous avez déjà un compte?
              <button @click="$emit('open-login')" class="text-[#9EB23B] underline underline-offset-2">Connexion</button>
            </p>
          </div>
        </div>
        <div class="dialog-overlay" v-if="showDialog">
          <div class="dialog-container bg-white flex flex-col items-center justify-center gap-2">
            <p :class="isSuccess ? 'text-green-500' : 'text-red-500'">{{ message }}</p>
            <button @click="showDialog = false" class="bg-blue-500 text-white px-4 py-2 rounded-xl">OK</button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
}


.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.p-progress-spinner-circle {
  stroke-dasharray: 89, 200;
  stroke-dashoffset: 0;
  stroke: #9EB23B;
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite, 6s ease-in-out infinite;
  stroke-linecap: round;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-container {
  position: relative;
  z-index: 9999;
  width: 300px;
  height: 100px;
}
</style>