<template>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-2 mb-5  ">
        <span class="p-input">
            <InputText v-model="restname" placeholder="Restaurant name" />
        </span>
        <span class="p-input">
            <InputText v-model="restemail" placeholder="email" class="w-full" />
        </span>
    </div>
    <span class="p-input">
        <InputText v-model="restaddres" placeholder="Restaurant Address" class="w-full" />
    </span>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-2 mb-5 mt-5 ">
        <span class="p-input">
            <InputText v-model="restcity" placeholder="Restaurant City" />
        </span>
        <span class="p-input">
            <InputMask id="basic" v-model="restphone" class="w-full" mask="0699999999" placeholder="Restaurant phone" />
        </span>
    </div>
    <span class="p-input">
        <InputText v-model="restwebsite" placeholder="Restaurant Web site" class="w-full" />
    </span>
</template>
<script>
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';


export default {
    components: {
        InputText,
        InputMask
    },
    data() {
        return {
            restwebsite: '',
            restphone: '',
            restcity: '',
            restaddres: '',
            restemail: '',
            restname: ''
        }
    },
    computed: {
        isFirstStepValid() {
            return (
                this.restname.trim() !== "" &&
                this.restemail.trim() !== "" &&
                this.restaddres.trim() !== "" &&
                this.restcity.trim() !== "" &&
                this.restphone.trim() !== "" &&
                this.restwebsite.trim() !== ""
            );
        },
    },


};
</script>